<template>
	<div class="container-fluid">
		<div class="row mt-2 mb-4">
			<div class="col-sm-12 text-right">
				<h1>Solicitud {{ $route.params.solicitud }}</h1>
			</div>
		</div>

		<!-- Botónes -->
		<div class="row mb-4">
			<div class="col-sm-2 offset-sm-6">
					<button class="btn secondary-btn" @click="ver_observaciones=true">Observaciones</button>
			</div>
			<div class="col-sm-2">
				<button class="btn secondary-btn" @click="ver_documentos=true">Ver documentos</button>
			</div>
			<div class="col-sm-2"><button class="btn warning-btn" @click="noviable_modal=true;">Solicitud no viable</button></div>
		</div>

		<Stepper :solicitud="solicitud" />

		<Modal v-if="ver_observaciones" :options="{close: true}" @close="ver_observaciones=false">
			<h2 class="title">Observaciones</h2>
			<div class="body">
				<div class="row form-group">
					<label for="" class="col-form-label col-sm-2">Observaciones</label>
					<div v-if="solicitud.observaciones.length > 0" class="col-sm-10">
						<div class="container-fluid">
							<div class="row list_observaciones" v-for="observacion in solicitud.observaciones" :key="observacion.id">
								<div class="col-sm-3" style="font-weight: bold;">{{ observacion.nombre }}</div>
								<div class="col-sm-9">{{ observacion.observacion }}</div>
							</div>
						</div>
					</div>
					<div v-else class="col-sm-10">No hay observaciones</div>
				</div>

				<form @submit="agregar_observacion">
					<div class="row form-group">
						<label for="nueva_observacion" class="col-form-label col-sm-2">Agregar comentario</label>
						<div class="col-sm-10">
							<div class="row">
								<div class="col-sm-9">
									<input v-model="nueva_observacion" type="text" name="nueva_observacion" id="nueva_observacion" class="form-control">
								</div>
								<div class="col-sm-3">
									<button class="btn secondary-btn">Agregar</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</Modal>

		<Modal v-if="ver_documentos" :options="{close: true}" @close="ver_documentos = false">
			<div class="title">Documentos</div>
			<div class="body">
				<div class="d-flex flex-wrap">
					<div v-for="archivo in solicitud.valija.archivos" class="col-md-4">
						<div class="card">
							<div class="card-body">
								<div class="text-center">
									<img src="https://static.bienparabien.com/constelacion/img/facturacion.svg" alt="Facturas" width="80px" />
								</div>
								<br />
								<h5 class="card-title">{{ archivo.nombre_real }}</h5>
								<p class="card-subtitle text-muted">Tipo: {{ archivo.tipo}} <br /> Peso: {{ $helper.bytesConverted(archivo.tamano)}}</p>

								<div class="row">
									<div class="col-sm-6">
										<button class="btn complementary-btn" @click="descargar_documento(archivo.valija_id, archivo.id, {type: archivo.mime})">Ver</button>
									</div>
									<div class="col-sm-6">
										<button class="btn principal-btn" @click="descargar_documento(archivo.valija_id, archivo.id, {name: archivo.nombre_real, type: archivo.mime})">Descargar</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-12 text-right"><button class="btn btn-primary" @click="descargar_documentos_todos">Descargar todos los documentos</button></div>
				</div>
			</div>
		</Modal>

		<Modal v-if="noviable_modal" :options="{type: 'error',close:true}" @close="no_viable_cancelar">
			<div class="title">Solicitud no viable</div>
			<div class="body">
				<p>Vas a marcar la solicitud como no viable, esto detendrá el proceso de la solicitud y solicitará interveción del personal de Bien para bien.</p>
				<p>Para continuar, es necesario que indiques la razón por la cual quieres marcar la solicitud como no viable.</p>

				<form action="">
					<div class="container-fluid">
						<div class="row">
							<label for="" class="col-form-label col-sm-3" style="background-color: #E6E6E6">Razón de solicitud inviable</label>
							<div class="col-sm-9"><textarea v-model="noviable" name="" id="" cols="30" rows="10" class="form-control"></textarea></div>
						</div>
					</div>
				</form>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-3 offset-sm-7"><button class="btn secondary-btn" @click="no_viable">Marcar como no viable</button></div>
					<div class="col-sm-2"><button class="btn danger-btn" @click="no_viable_cancelar">Cancelar</button></div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import api from '@/apps/formalizacion/api/v1/formalizacion'

	import Modal from '@/components/Modal'
	import Stepper from './steps/Stepper'

	export default {
		components: {
			Modal,
			Stepper
		}
		,data: function() {
			return  {
				solicitud: {
					observaciones: []
				}
				,valija: {
					archivos: []
				}
				,ver_observaciones: false
				,nueva_observacion: null
				,ver_documentos: false
				,noviable: null
				,noviable_modal: false
			}
		}
		,mounted: function() {
			this.obtener_solicitud();
		}
		,methods: {
			obtener_solicitud: async function() {
				this.solicitud = (await api.detallesSolicitud(this.$route.params.solicitud)).data;
				this.$log.info('solicitud: ',this.solicitud);
			}
			,agregar_observacion: async function(e) {
				if (e) e.preventDefault();

				try {
					this.solicitud.observaciones = (await api.agregarObservacion(this.solicitud.solicitud, {observacion: this.nueva_observacion})).data;
					this.$log.info('solicitud', this.solicitud);
					this.nueva_observacion = null;
				}catch(e) {
					this.$log.info('e', e);
				}
			}
			,descargar_documento: function(valija, documento, opciones) {
				api.descargar_documentos(this.$route.params.solicitud, valija, documento, opciones);
			}
			,descargar_documentos_todos: function() {
				api.descargar_documentos_todos(this.solicitud.solicitud, this.solicitud.valija.id, {name: 'documentos_solicitud_'+this.solicitud.solicitud, type:"application/zip"});
			}
			,no_viable: async function() {
				try {
					if (this.noviable.length <= 5)
						return this.$helper.showMessage('Error','Tienes que dejar una descripción mas larga','error','alert');

					await api.agregarObservacion(this.solicitud.solicitud, {observacion: 'Solicitud '+this.solicitud.solicitud+' marcada como no viable por "'+this.$auth.getUser().nombre+'", entra en modo pausa'});
					await api.agregarObservacion(this.solicitud.solicitud, {observacion: this.noviable});
					await api.noviable(this.solicitud.solicitud, {razon: this.noviable});

					this.no_viable_cancelar();
					this.$router.push({name: 'formalizacion_valuadores'});
				}catch(e) {
					this.$log.error('err', e);
					this.$helper.showAxiosError(e, 'Error');
				}
			}
			,no_viable_cancelar: function() {
				this.noviable_modal = false;
				this.noviable = null;
			}
		}
	}
</script>

<style lang="scss" scoped>
	h2 {
		color: #fff !important;
	}

	.list_observaciones {
		&:nth-child(2n+1) {
			background-color: #E6E6E6;
		}
	}

	.form-group {
		display: flex;
		align-items: center;
	}
</style>